import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../../../components/issueIndex";
export const _frontmatter = {
  "title": "Volume 6, Issue 1"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This issue has not been released yet. Individual articles may have been released in our online-first model of publication. Check back in April 2022.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      